<!--
 * @Description:
 * @Author: hanyu
 * @Date: 2021-03-24 15:56:35
 * @LastEditTime: 2021-03-26 11:05:29
 * @LastEditors: hanyu
-->
<template>
  <div class="login-container">
    <div class="company-info">
      <nuxt-link to="/">
        <img class="logo" :src="getLogoUrl" alt />
      </nuxt-link>
      <div>{{ getSiteName }}</div>
    </div>

    <p class="title">让学习变得轻松简单</p>
    <div class="login-content">
      <AccountContainer ref="accountContainer" @success="loginSuccess"></AccountContainer>
    </div>
  </div>
</template>

<script>
import AccountContainer from '@/components/account/components/account-container';
import { accountApi } from '@/api/account';
import { companyApi } from '../../api/company';
export default {
  layout: 'empty',
  name: 'Login',
  components: {
    AccountContainer
  },
  data() {
    return {};
  },
  computed: {
    userInfo() {
      return this.$store.getters['user/userInfo'];
    },
    // 获取logoUrl
    getLogoUrl() {
      const companyInfo = this.$store.state.companyInfo || {};
      return companyInfo.companyLogo;
    },
    // 获取公司名称
    getSiteName() {
      const companyInfo = this.$store.state.companyInfo || {};
      return companyInfo.siteName;
    }
  },
  mounted() {
    this.getCompanyInfo();
    this.$refs.accountContainer.initListenEnterKeyDown();
    this.getUserDetail();
  },
  methods: {
    async getCompanyInfo() {
      const result = await companyApi.getCompanyInfo();
      this.$store.commit('SET_COMPANY_INFO', result.data);
    },
    loginSuccess() {
      if (this.$route.query.redirect) {
        window.location.replace(decodeURIComponent(this.$route.query.redirect));
      } else {
        // 默认首页
        this.$router.replace({
          path: '/'
        });
      }
    },
    // 获取用户详情 校验token是否失效
    async getUserDetail() {
      try {
        await accountApi.getUserDetail();
        if (!this.lodash.isEmpty(this.userInfo)) {
          this.loginSuccess();
        }
      } catch (e) {
        this.$smartSentry.captureException(e);
        0;
        console.error(e);
      } finally {
        // 拦截器里会弹出登录弹框 在登录页就不需要了 直接关闭弹框
        this.$store.commit('user/changeAccountModal', { isShow: false });
      }
    }
  },
  head() {
    const siteName = this.getSiteName;
    console.log(siteName);
    return {
      title: siteName,
      link: [{ rel: 'icon', type: 'image/x-icon', href: this.getLogoUrl }],
      meta: [
        {
          hid: 'keywords',
          name: 'keywords',
          content: `${siteName},${siteName}登录,${siteName}注册,${siteName}教育网,${siteName}账号注册,${siteName}用户登录`
        },
        {
          hid: 'description',
          name: 'description',
          content: `欢迎大家登录或注册${siteName}账号，更多课程敬请期待。`
        }
      ]
    };
  }
};
</script>

<style lang="less" scoped>
@import './index.less';
</style>
