// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/_css-loader@5.2.7@css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/_css-loader@5.2.7@css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/image/login/login-bg.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".login-container[data-v-5b09b627]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;background-size:100% 100%;height:100vh;line-height:1;position:relative;width:100%}.login-container .title[data-v-5b09b627]{color:#fff;font-size:60px;left:230px;line-height:1.2;position:absolute;top:398px}.login-container .company-info[data-v-5b09b627]{color:#fff;display:flex;font-size:24px;font-weight:800;left:89px;letter-spacing:1px;line-height:42px;margin-right:88px;position:absolute;top:56px}.login-container .company-info .logo[data-v-5b09b627]{height:42px;width:42px}.login-container .login-content[data-v-5b09b627]{background:hsla(0,0%,100%,.9)!important;border-radius:10px;height:510px;padding:40px;position:absolute;right:12%;top:26%;width:430px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
